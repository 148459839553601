function eventSize() {
	//get widest caption layer in Metaslider Layer caption
	/*
	var captionWidth = Math.max.apply( null, $( elems ).map( function () {
	    return $('.msHtmlOverlay' ).outerWidth( true );
	}).get() );
	*/
	var windowWidth = jQuery("body").width();
	// var captionMargin = (windowWidth - captionWidth) / 2;
	var windowHeight = jQuery( window ).height();
    var headerHeight = jQuery(".header-container").height();
	var footerHeight = jQuery("footer .container").outerHeight(true);

	// alert (footerHeight);
	
	jQuery('footer .metaslider').css("height", footerHeight);
	//jQuery('footer .metaslider img').attr("height", footerHeight);
	
	
}

jQuery(document).ready(eventSize);
jQuery(window).resize(eventSize); 

